.wrapper {
  padding: 40px 16px;
}

.content {
  color: var(--darkBlue);
  margin: auto;
  text-align: center;
}

.icon {
  align-items: center;
  background: var(--white);
  border-radius: 50%;
  color: var(--darkBlue);
  display: inline-flex;
  height: 80px;
  justify-content: center;
  width: 80px;

  svg {
    height: 32px;
    width: 32px;
  }
}

.iconGrey {
  background: var(--backgroundUp);
}

.title.title {
  margin-bottom: 4px;
  margin-top: 16px;
}

.text.text {
  margin-bottom: 24px;
}
