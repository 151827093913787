.detailListItem {
  border-bottom: 1px solid var(--border);

  &:last-child {
    border: none;
  }

  &:global(.MuiListItem-root) {
    justify-content: space-between;
    padding: 24px 32px;
  }

  .detailListItemValue {
    text-align: right;
  }
}
