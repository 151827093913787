.header {
  border-bottom: 1px solid var(--border);
  color: var(--description);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 32px;
}

.headerLine {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.description {
  color: var(--description);
}
