@import "../../../../../../styles/placeholders";

.drawerPaper {
  max-height: 100vh;
  max-width: 90%;
  width: 880px;
}

.footer {
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 21px;
  width: 100%;
}

.main {
  @extend %scroll-effect;

  height: 100%;
  overflow-y: scroll;
  padding: 32px;
}

.actionBlock {
  overflow: hidden;
  position: relative;

  .loadingIndicator {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}
