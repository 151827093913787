.container {
  padding: 32px;
  width: 100%;

  .loadContent .skeleton {
    margin-top: 10px;
  }
}

.noContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}
