.consent.consent.consent {
  >:global(.MuiPaper-root) {
    border-top: 1px solid var(--border);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0 -32px -24px;
    padding: 24px 32px;
  }

  :global(.MuiFormControlLabel-root) {
    margin-bottom: 0;
  }
}

.innerAccordionsContainer.innerAccordionsContainer {
  margin-top: 0;
}
