.listOfActionsDescription {
  padding-bottom: 24px;
}

.actions {
  gap: 16px;
  margin-top: 24px;

  .actionWrapper {
    display: flex;
  }
}
