.detailList {
  border-radius: 12px;

  .listTitle {
    color: var(--description);
  }

  .detailListItem {
    border-bottom: 1px solid var(--border);
    border-left: 1px solid var(--border);

    &:last-child {
      border: none;
      border-left: 1px solid var(--border);
    }

    &:global(.MuiListItem-root) {
      justify-content: space-between;
      padding: 24px 32px;
    }
  }
}
