.control {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  cursor: none;
}

.drawer:global(.MuiDrawer-root) {
  z-index: 2147483647;
  
  :global(.MuiPaper-root) {
    padding: 24px;
  }
}
