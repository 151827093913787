.wrapper {
  align-items: center;
  display: flex;
}

.icon {
  height: 20px;
  margin-right: 14px;
  width: 20px;
}
