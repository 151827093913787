.collapsed {
  &:global(.MuiPaper-root) {
    background-color: transparent;
  }

  :global(.MuiTypography-h3 > .MuiTypography-paragraph) {
    color: var(--description);
  }
}

.content {
  margin-top: 8px;
}
