.chips {
  align-items: center;
  display: flex;
  gap: 16px;
  width: 100%;

  :global(.MuiChip-root) {
    :global(.MuiChip-icon) {
      height: 16px;
      width: 20px;
    }
  }
}
