.container {
  align-items: center;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  border-top: 1px solid var(--border);
  display: flex;
  height: 65px;
  position: relative;
  z-index: 2;

  section {
    align-items: center;
    border-right: 1px solid var(--border);
    display: flex;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-end;
    padding: 0 32px;
    width: 100%;

    &:last-child {
      border-right: none;
    }
  }

  .search {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .searchInput {
    padding-left: 8px;
  }

  .searchIcon {
    margin-left: -8px;
  }

  .button {
    padding: 20px 32px;
    white-space: nowrap;
    width: auto;
  }
}

.filterLabel {
  color: var(--darkBlue);
  margin-right: auto;
}

section.filtersWithLabel {
  justify-content: space-between;
}
