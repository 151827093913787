.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.actionButton {
  &:global(.MuiIconButton-root) {
    padding: 0;
  }
}
