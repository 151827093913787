.search {
  &:global(.MuiFormControl-root .MuiInputBase-root.Mui-focused) {
    border-color: transparent;
  }

  .searchInput {
    padding-left: 8px;
  }

  .searchIcon {
    margin-left: -8px;
  }
}
