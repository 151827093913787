.sidebarPanel {
  overflow: hidden;
  padding: 0;

  &.stickyPanel {
    margin-top: 1.5rem;
    position: sticky;
    top: 1rem;
  }

  :global(.MuiAccordionSummary-root) {
    padding: 24px 32px;

    &:global(.Mui-expanded) {
      border-bottom: 1px solid var(--border);
    }
  }

  :global(.MuiAccordionDetails-root) {
    padding: 24px 32px;
  }

  h3 {
    color: var(--description);
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 203px);
}

.content {
  flex-grow: 1;
  padding: 32px;
}

.quoteContainer.quoteContainer {
  display: block;
}

.alert {
  margin: 16px;
}
