@import "./variables";
@import "./mixins";

%scroll-effect {
  &::-webkit-scrollbar {
    height: 14px;
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: var(--border);
    border: 4px solid rgb(0 0 0 / 0%);
    border-radius: 99px;
  }
}
