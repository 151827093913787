@import "../../../../../../../styles/variables";

.remainingElementsList {
  background: var(--white);
  border-radius: 8px;
  box-shadow: $box-shadow;
  display: none;
  min-width: 100px;
  position: absolute;
  right: 0;
  top: 32px;
  z-index: 1;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 12px;
      white-space: nowrap;
    }
  }
}

.remainingElements {
  display: inline-flex;
  position: relative;

  > div {
    cursor: pointer;
  }

  &:hover {
    .remainingElementsList {
      display: block;
    }
  }
}
