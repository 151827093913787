.creditScore {
  margin-top: 24px;

  > div {
    margin-top: 2rem;
  }

  button {
    margin-top: 1rem;
  }

  .information {
    background-color: var(--white);
    border: 1px solid var(--border);
    border-radius: 12px;
    color: var(--darkBlue);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    div {
      border: none;
    }

    svg {
      color: var(--darkBlue);
      font-weight: 700;
    }
  }
}

.fileUploader {
  margin-top: 24px;
}

.alert {
  margin-bottom: 24px;
}
