@mixin devices($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 599px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (max-width: 899px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (max-width: 1535px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (min-width: 1536px) {
      @content;
    }
  }
}
