.drawer {
  :global(.MuiDrawer-paper) {
    height: 100%;
    max-width: 100%;
    width: 640px;
  }
}

div.tabs {
  background: var(--white);
  border-bottom: 1px solid var(--border);
  flex-shrink: 0;
  height: 73px;
}

.section {
  flex-grow: 1;
  flex-shrink: 1;
}

.content {
  padding: 32px;
}
