.section {
  align-content: flex-start;
  flex-grow: 1;
  padding: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
