.section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 420px;
}

.controls {
  > div {
    margin-bottom: 24px;
  }
}

h1.heading {
  color: var(--darkBlue);
  margin-bottom: 24px;
}

p.subtitle {
  color: var(--darkBlue);
  margin-bottom: 32px;
}

div.inputLast {
  margin-bottom: 15px;
}

button.submitButton {
  margin-bottom: 10px;
}
