.progressBar {
  margin-top: 16px;
}

.text {
  color: var(--description);

  &.locked {
    color: var(--disabled);
  }
}
