.skeletonTitle {
  font-size: 2rem;
}

.skeletonBody {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.skeletonFooter {
  min-height: 210px;
}
