.section {
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.stepper {
  overflow: hidden;

  &:global(.MuiPaper-root) {
    padding-bottom: 8px;
  }
}

.footer {
  align-items: center;
  background: var(--white);
  box-shadow:
    0 78px 428px rgb(31 46 93 / 1.38%),
    0 45.9264px 223.245px rgb(31 46 93 / 2.19%),
    0 23.5872px 104.774px rgb(31 46 93 / 2.81%),
    0 9.4848px 45.8816px rgb(31 46 93 / 3.62%),
    0 2.1216px 19.8592px rgb(31 46 93 / 5%);
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 24px 32px;
  position: relative;
}

.loading:global(.MuiLinearProgress-root) {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
