.email {
  color: var(--description);
}

.avatar {
  flex-shrink: 0;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
