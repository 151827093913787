.listItem {
  &:global(.MuiListItem-root) {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
}

.listItemTitle {
  align-items: center;
  display: flex;

  &::before {
    background: red;
    border-radius: 50%;
    content: "";
    display: block;
    height: 8px;
    margin-right: 8px;
    width: 8px;
  }

  &[data-mark-color-index="0"] {
    &::before {
      background: var(--darkBlue);
    }
  }

  &[data-mark-color-index="1"] {
    &::before {
      background: var(--blue);
    }
  }

  &[data-mark-color-index="2"] {
    &::before {
      background: var(--lightBlue);
    }
  }

  &[data-mark-color-index="3"] {
    &::before {
      background: var(--lightestBlue);
    }
  }
}
