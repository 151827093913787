.footer {
  align-items: center;
  background: var(--white);
  display: flex;
  height: 80px;
  justify-content: space-between;
}

.status {
  align-items: center;
  display: flex;
  padding: 16px 32px;
  position: relative;
  width: calc(33.3333% + 32px);
}

.divider {
  color: var(--border);
  height: 100%;
  width: 1.5px;
  z-index: 1;
}

.navDescription.navDescription {
  margin-left: auto;
}

.navTitle {
  color: var(--darkBlue);
}

.buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 16px 32px;
  width: calc(66.6667%);
}

.dismiss.dismiss {
  margin-right: auto;
}
