.buttonChevron {
  color: var(--darkBlue);
  height: 20px;
  margin-left: 4px;
  width: 20px;
}

.button {
  align-items: center;
  color: var(--description);
  cursor: pointer;
  display: flex;
  white-space: nowrap;

  &:hover {
    .buttonChevron {
      color: var(--gold);
    }
  }

  &.selected {
    color: var(--darkBlue);
  }
}
