.detailList {
  &:global(.MuiPaper-root) {
    border-radius: 12px;
  }

  .listTitle {
    color: var(--description);
  }

  .detailListItem {
    border-bottom: 1px solid var(--border);

    &:last-child {
      border: none;
    }

    &:global(.MuiListItem-root) {
      justify-content: space-between;
      padding: 24px 32px;
    }
  }

  .listColumn {
    &:first-child {
      border-right: 1px solid var(--border);
    }
  }
}
