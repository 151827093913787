.section {
  background-color: var(--background);

  form {
    background: var(--white);
    padding: 24px;
  }

  .subHeaderBtn {
    align-items: center;
    display: flex;
    gap: 16px;
  }

  .buttonSorter {
    gap: 5px;
    margin-left: 0.5rem;
  }

  .selectBy {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 30px;

    .selectByComponent {
      color: var(--darkBlue);
      font-weight: bold;
      padding-right: 16px;
      padding-top: 3px;

      & > svg {
        color: var(--darkBlue);
        top: calc(50% - 0.6em);
      }
    }
  }
}
