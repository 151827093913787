.footer {
  align-items: center;
  background: var(--white);

  // padding: 16px 32px;
  display: flex;
  justify-content: space-between;
}

.navigation {
  align-items: center;
  display: flex;
  padding: 16px 32px;
  position: relative;
  width: 35%;
}

.divider {
  color: var(--border);
  height: 100%;
  width: 1.5px;
  z-index: 1;
}

.navTitle {
  color: var(--darkBlue);

  &:global(.MuiTypography-root) {
    margin-right: auto;
  }
}

.navDescription {
  margin-left: 15px;
}

.navButton {
  &:global(.MuiButton-root) {
    margin: 0 8px;
    min-width: 56px;
    padding: 8px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px;
  width: 65%;
}
