.header {
  padding: 24px 32px;
}

.headerTitleWrapper {
  align-items: center;
  display: flex;

  &.iconAfterText {
    justify-content: flex-start;
  }

  &.iconAtEnd {
    justify-content: space-between;
  }
}

.headerTitle {
  color: var(--darkBlue);

  &.locked {
    color: var(--disabled);
  }
}

.progressStatus {
  margin-top: 4px;
}

.wrapper {
  background: var(--white);

  &.locked {
    background: var(--backgroundUp);
  }
}

.infoIcon.infoIcon {
  color: var(--description);
  height: 24px;
  width: 24px;
}

.tooltipWrapper {
  margin-left: 12px;
  margin-right: auto;
  padding: 0;
}
