.step {
  align-items: center;
  display: flex;
  padding: 16px;
}

.information {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.alert {
    color: var(--error);
  }
}

.navLink {
  flex-grow: 0;
  flex-shrink: 0;
}
