.container {
  .paper {
    border: none;
    display: flex;
    flex-direction: column;
    padding: 0;

    .paperInfo {
      display: flex;
      justify-content: flex-start;
      padding: 12px 24px;

      .paperInfoHeaders {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        a {
          cursor: pointer;
        }
      }

      .img {
        border: solid 1px var(--border);
        border-radius: 4px;
        margin-right: 11px;
        padding: 3px;
      }

      .delete {
        margin-left: auto;
      }
    }
  }
}
