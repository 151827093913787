.wrapper {
  background-color: var(--backgroundUp);
  border-radius: 32px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  padding: 4px;
  position: relative;
}

.selection {
  background: var(--gold);
  border-radius: 32px;
  bottom: 4px;
  position: absolute;
  top: 4px;
  transition:
    left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: auto;
}

.chip {
  color: var(--darkBlue);
  cursor: pointer;
  padding: 4px 12px;
  position: relative;
  transition: color ease-in-out 0.2s;
  user-select: none;
}

.active {
  color: var(--white);
  transition: color ease-in-out 0.2s;

  svg {
    path {
      fill: var(--white);
      transition: color ease-in-out 0.2s;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;

  svg {
    cursor: pointer;
    height: 28px;
    position: relative;
    transition: color ease-in-out 0.2s;
    user-select: none;
    width: 20px;
  }
}
