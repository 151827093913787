.documentsListWrapper {
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 8px 0;
}

.documentsListItem {
  border-bottom: 1px solid var(--border);

  &:last-child {
    border-bottom: none;
  }
}
