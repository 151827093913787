.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.containerItem {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.title {
  &:global(.MuiTypography-root) {
    margin-bottom: 0;
  }
}

.fileInfoBlock {
  align-items: center;
  display: flex;
}

.info {
  composes: containerItem;
  width: 35%;
}

.button {
  composes: containerItem;
  text-align: right;
  width: 20%;
}
