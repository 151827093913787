.statusWrapper {
  padding: 32px;
}

.contactBlock {
  align-items: center;
  border: none;
  display: flex;
  margin-top: 24px;
}

.infoIcon {
  flex-shrink: 0;
  height: 20%;
  margin-right: 14px;
  width: 20px;
}

.phoneNumber {
  color: var(--gold);
}
