.helperPage {
  height: 0;
  width: 210mm;
}

.page {
  height: 297mm;
  width: 210mm;

  + .page {
    border-top: 10mm solid var(--background);
  }
}
