.actionBlockWrapper {
  margin: 8px 0 24px;

  :global(.MuiPaper-root) {
    justify-content: space-between;
  }
}

.notification {
  p {
    margin: 0;
  }
}
