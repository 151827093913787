.container {
  padding-left: 16px;

  .dotContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 4px;
    width: 30px;

    .dot {
      background-color: var(--darkBlue);
      border: solid 1px;
      border-color: var(--darkBlue);
      border-radius: 50%;
      height: 12px;
      min-height: 12px;
      width: 12px;
    }

    .line {
      background-color: var(--darkBlue);
      display: block;
      height: 100%;
      margin-bottom: -4px;
      width: 1px;
    }
  }

  .elementInProgress {
    .dot {
      background-color: transparent;
    }

    .line {
      background-color: var(--disabled);
    }

    p {
      font-weight: bold;

      small {
        font-weight: normal;
      }
    }
  }

  .elementLocked {
    .dot {
      background-color: transparent;
      border-color: var(--disabled);
    }

    .line {
      background-color: var(--disabled);
    }

    p {
      color: var(--disabled) !important;
    }
  }

  .elementError {
    .dot {
      background-color: transparent;
      border-color: var(--error);
    }

    .line {
      background-color: var(--disabled);
    }

    p {
      color: var(--error) !important;
    }
  }

  .element {
    display: flex;
    flex-direction: row;

    p {
      color: var(--darkBlue);
      font-size: 16px;
      margin-top: 0;
    }

    .newConditionsLabel {
      color: var(--error);
      font-size: 12px;
      font-weight: 400;
    }
  }

  .elementDone {
    &:last-child {
      p {
        font-weight: bold;

        small {
          font-weight: normal;
        }
      }
    }
  }
}
