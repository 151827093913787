.controlPanel {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  padding: 32px;
  width: 100%;

  .controlPanelStack {
    max-width: 1400px;
    width: 100%;

    .controlPanelStackItem {
      align-items: stretch;
      align-self: stretch;
      display: grid;
      flex-grow: 1;
      max-width: 33.3%;

      &:global(.MuiSkeleton-root) {
        height: 400px;
      }

      .stepperWidget {
        border: none;
        overflow: hidden;
        padding: 0;

        &.locked {
          background-color: var(--backgroundUp);
        }
      }

      &:nth-child(1) {
        border: 1px solid var(--border);
        border-bottom-left-radius: 12px;
        border-right: none;
        border-top-left-radius: 12px;
        overflow: hidden;

        .stepperWidget {
          border-bottom-left-radius: 12px;
          border-top-left-radius: 12px;
        }
      }

      &:nth-child(2) {
        border: 1px solid var(--border);
      }

      &:nth-child(3) {
        border: 1px solid var(--border);
        border-bottom-right-radius: 12px;
        border-left: none;
        border-top-right-radius: 12px;

        .stepperWidget {
          border-bottom-right-radius: 12px;
          border-top-right-radius: 12px;
        }
      }
    }
  }
}
