.conditionItemContent {
  .conditionItemActive,
  .conditionItem {
    align-items: center;
    border-left: solid 4px;
    display: flex;
    justify-content: flex-start;
    padding: 12px 23px 12px 24px;
    width: 100%;

    &.Mui-selected {
      border-color: var(--gold);
      border-left: solid 4px;
    }

    .conditionInfo {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      width: 45%;
    }

    .conditionStatus {
      width: 25%;
    }

    .keyClosingTaskIcon {
      height: 28px;
      margin-right: 16px;
      width: 28px;
    }

    .conditionNotificationsGrid {
      display: flex;
      gap: 20px;
      margin-left: auto;

      .notificationIcon {
        height: 25px;
        width: 25px;
      }
    }
  }

  .conditionItem {
    border-color: transparent;
  }

  .conditionItemActive {
    border-color: var(--gold);
  }
}
