div.majorGroup {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;

  > * {
    width: 100%;

    label {
      background: var(--backgroundUp);
      border-radius: 12px;
      margin: 0;
      padding: 14px;
      width: 100%;
    }
  }
}

.label {
  &:global(.MuiInputLabel-root) {
    margin-bottom: 18px;
  }
}

.error {
  :global(.MuiSvgIcon-root) {
    color: var(--error);
  }
}
