.tableWrapper.tableWrapper {
  overflow: hidden;

  :global(.ant-table-tbody .ant-table-row:not(:nth-child(2))) {
    :global(.ant-table-cell.ant-table-cell) {
      border-top: 1px solid var(--border);
    }
  }
}

.buttons {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.addressList {
  margin: 0;
  padding-left: 24px;
}

.constructionInspection.constructionInspection {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 16px;
}
