.subsection {
  overflow: hidden;

  :global(.MuiTypography-h3) {
    font-size: 16px;
  }
}

.divider {
  left: -50px;
  position: absolute;
  width: 200%;
}

.container {
  margin-top: 24px;
}
