.error {
  color: var(--error);
}

.title {
  align-items: center;
  display: flex;
  gap: 12px;

  svg {
    height: 24px;
    width: 24px;
  }
}
