.spacing {
  margin-bottom: 16px;
}

.divided {
  border-bottom: 1px solid var(--border);

  &:global(.MuiPaper-root) {
    border-radius: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

.addButton {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left;
  width: 100%;
}

.addRegularButton {
  margin: 0 0 24px 24px !important; // necesary for overwritting
}

.addLabel,
.removeLabel {
  align-items: center;
  display: flex;
  gap: 8px;

  svg {
    color: var(--darkBlue);
    height: 24px;
    width: 24px;
  }
}

.removeLabel {
  justify-content: space-between;

  button {
    margin: -8px 0;
  }
}

.buttonDescriptionWrapper {
  margin: 0 24px 24px;
}

.buttonDescription.buttonDescription {
  color: var(--description);
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    &:global(.MuiTypography-root) {
      color: var(--disabled);
    }
  }
}
