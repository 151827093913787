p.checkboxLabel {
  margin-bottom: 7px;
}

.checkboxList {
  display: flex;
  flex-wrap: wrap;
  gap: 0 48px;

  label {
    margin-right: 0;
    white-space: nowrap;
  }
}
