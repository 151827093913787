@import "../../../../styles/variables";
@import "../../../../styles/placeholders";

.container {
  position: relative;
}

p.panelBodyTitle {
  color: var(--darkBlue);
  margin: 0;
  padding: 8px 20px;
}

.panelWrapper {
  position: absolute;
  right: 0;
  top: 24px;
}

.panel {
  background: var(--white);
  border-radius: 12px;
  box-shadow: $box-shadow;
  margin-top: 6px;
  min-width: 240px;
}

.panelHeader {
  align-items: center;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
}

.panelBody {
  padding: 5px 0;

  ul {
    @extend %scroll-effect;

    list-style-type: none;
    margin: 0;
    max-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  li {
    padding: 9px 20px;
  }
}

.panelSelected {
  color: var(--description);
}

.panelFooter {
  padding: 16px 20px;
}

.applyButton {
  width: 100%;
}
