.accordionSummary {
  svg {
    color: var(--darkBlue);
    height: 24px;
    width: 24px;
  }
}

.info {
  display: flex;
  gap: 10px;

  :global(.MuiTypography-paragraph) {
    margin: 4px 0 0;
  }
}
