.container {
  & > div {
    min-width: fit-content;
  }

  .chipModifier {
    border: 1px solid currentcolor;
    cursor: pointer;
  }
}
