.listNotification {
  &:global(.MuiAlert-root) {
    border-radius: 0;
    border-top: 1px solid var(--border);
    padding: 4px 24px;
  }
}

.action {
  &:global(.MuiButton-root) {
    align-self: center;
  }
}
