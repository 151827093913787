.icon {
  height: 24px;
  width: 24px;
}

.headerIcons {
  align-items: center;
  display: flex;
  gap: 10px;
}

.statusIcon {
  color: var(--darkBlue);
  composes: icon;
}

.listItemIcon.listItemIcon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  min-width: auto;
}

.statusRedIcon {
  color: var(--error);
  composes: icon;
}

.statusLockedIcon {
  color: var(--disabled);
  composes: icon;
}

.headerIcon {
  margin: -8px;

  svg {
    color: var(--darkBlue);
    height: 24px;
    width: 24px;
  }
}
