@import "../../../../../../../../styles/placeholders";

.scrollableList {
  @extend %scroll-effect;

  border: 1px solid var(--border);
  border-radius: 12px;
  margin-top: 8px;
  max-height: 140px;
  overflow-y: auto;
  padding: 12px;

  & > p {
    margin-bottom: 4px;
  }
}
