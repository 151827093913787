.section {
  color: var(--darkBlue);
}

.stepper {
  overflow: hidden;

  &:global(.MuiPaper-root) {
    padding-bottom: 8px;
  }
}
