.wrapper {
  position: relative;
}

.trigger {
  bottom: 0;
  position: absolute;
  right: 0;
}

.iconButton {
  &:global(.MuiIconButton-root) {
    background-color: var(--backgroundUp);

    &:hover {
      background-color: var(--backgroundUp);
    }
  }
}

.input {
  display: none;
}

.menuItem {
  &:global(.MuiMenuItem-root) {
    padding: 16px 22px;
  }
}
