.documentsListWrapper {
  border: 1px solid var(--border);
  border-radius: 12px;
}

.documentsListItem {
  border-bottom: 1px solid var(--border);

  &:last-child {
    border-bottom: none;
  }
}

.iconWrapper.iconWrapper {
  margin-bottom: auto;
  padding: 0;
}

.infoIcon.infoIcon {
  color: var(--gold);
}

.notification {
  margin: 32px 0;
}
