.infoIcon.infoIcon {
  color: var(--darkBlue);
  height: 24px;
  width: 24px;
}

.iconWrapper.iconWrapper {
  padding: 0 0 0 8px;
}

.title {
  margin: 0 auto;
  max-width: 150px;
  text-align: center;
}

.wrapper {
  height: 100%;
}
