.infoIcon {
  color: var(--description);
  height: 24px;
  width: 24px;

  &:hover {
    color: var(--gold);
  }
}

.iconWrapper {
  padding: 0;
}

.chevronIcon {
  color: var(--darkBlue);
}

.button.button {
  height: 56px;
  padding-right: 26px;

  &:hover {
    background-color: var(--backgroundUp);
  }

  &.active {
    background-color: var(--backgroundUp);

    &::after {
      background: var(--gold);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }
  }

  &.alert {
    color: var(--error);

    .chevronIcon {
      color: var(--error);
    }
  }

  &.disabled {
    opacity: 1;
  }
}

.title {
  &.active,
  &.bold {
    span {
      font-weight: 700;
    }
  }

  &.locked {
    color: var(--disabled);
  }
}

.link {
  color: var(--darkBlue);
  text-decoration: none;
}
