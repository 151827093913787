.icon {
  align-items: flex-start;
  display: flex;
  font-size: 24px;
  height: 100%;
  margin: 0 14px 0 0;
}

.title {
  &:global(.MuiTypography-root) {
    font-size: 18px;
    margin-bottom: 4px;
  }
}

.fonts,
.description {
  font-size: 14px;
}

.description {
  color: var(--description);
}
