$size: 24px;

.progress {
  &:global(.MuiCircularProgress-root) {
    height: $size !important;
    margin-right: 0.8rem;
    width: $size !important;

    svg {
      height: $size;
      width: $size;
    }
  }
}
