.invitationToClosing {
  margin-top: 32px;

  .title {
    margin-bottom: 16px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 24px;
    width: 100%;

    .infoItem {
      align-items: center;
      display: flex;
      max-width: 100%;

      svg {
        height: 24px;
        margin-right: 8px;
        width: 24px;

        path {
          fill: var(--description);
        }
      }
    }
  }
}
