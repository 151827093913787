.container {
  border-bottom: solid 1px var(--border);
  padding: 24px;
  transition: 0.3s;

  &:hover {
    background-color: var(--backgroundHighlight);
  }

  .header {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0;

    .bell {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    .bell.bellActive {
      color: var(--darkBlue);
    }

    .bell svg {
      font-size: 18px;
      margin-right: 6px;
    }
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
  }
}
