.removeLabel {
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    color: var(--darkBlue);
    margin: -8px 0;
  }
}

button.tooltipOnly {
  svg {
    color: var(--description);
  }
}
