.divider {
  color: var(--border);
  left: -32px;
  position: relative;
  width: calc(100% + 64px);

  &:global(.MuiDivider-root) {
    margin-top: 12px;
  }
}

.label {
  &:global(.MuiTypography-root) {
    font-size: 32px;
  }
}
