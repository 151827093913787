@import "./styles/placeholders";

body {
  background: var(--background);
  font-family:
    Manrope,
    -apple-system,
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.MuiPopover-root .MuiPaper-root.MuiPaper-root {
  @extend %scroll-effect;

  border-radius: 8px;
  margin: 8px 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

* {
  box-sizing: border-box;
}
