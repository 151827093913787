.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
}

.cancel {
  &:global(.MuiButton-text) {
    margin-right: 24px;
  }
}
