.footer {
  display: flex;
}

.button {
  &:global(.MuiButton-root) {
    margin-left: auto;
    padding: 12px 16px;
  }
}
