.documentsListWrapper {
  border: 1px solid var(--border);
  border-radius: 12px;
}

.fileUploader {
  border-bottom: 1px solid var(--border);

  &:last-child {
    border-bottom: 0;
  }

  :global(.MuiPaper-root) {
    border: none;
    padding: 12px 24px;
  }
}
