.wrapper {
  background: var(--white);
  border: 1px solid var(--border);
  border-radius: 12px;
  margin-top: 24px;
  padding: 24px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  button {
    flex-shrink: 0;
    margin-top: 6px;
  }
}
