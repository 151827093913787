.content {
  padding: 32px 24px 28px;
}

.footer {
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: flex-end;
  padding: 24px 32px;
}
