.UploadedFileCardStatus {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 6px 26px;

  svg {
    height: 20px;
    margin-right: 10px;
    width: 20px;
  }

  &.approved {
    color: var(--green);

    svg {
      color: var(--green);
    }
  }

  &.review {
    color: var(--darkBlue);

    svg {
      color: var(--darkBlue);
    }
  }

  &.denied {
    color: var(--error);

    svg {
      color: var(--error);
    }
  }
}
