.headerIcons {
  align-items: center;
  display: flex;
  gap: 10px;
}

.statusIcon {
  color: var(--darkBlue);
  height: 24px;
  width: 24px;
}

.headerIcon {
  a {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  svg {
    color: var(--darkBlue);
    height: 24px;
    width: 24px;
  }
}
