.toastBar {
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.wrapper {
  max-height: 100%;
  max-width: 100%;
  padding: 15px 30px;
  position: absolute;
  right: 0;
  top: 0;
}
