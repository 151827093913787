.title {
  &:global(.MuiTypography-root) {
    margin-top: 16px;
  }
}

.description {
  &:global(.MuiTypography-root) {
    margin-top: 4px;
  }
}
