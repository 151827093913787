.footer {
  align-items: center;
  background-color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  position: relative;
}

.loading:global(.MuiLinearProgress-root) {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
