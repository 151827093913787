.wrapper {
  border-radius: 4px;
  display: flex;
  height: 4px;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative;
  width: 100%;

  > * {
    flex: 1;

    + * {
      margin-left: 4px;
    }
  }
}

.divisions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  :global(.MuiTypography-root) {
    font-size: 12px;
  }
}

.filled {
  background: var(--darkBlue);
}

.empty {
  background: var(--border);
}
