.tableWrapper.tableWrapper {
  overflow: hidden;
  position: relative;

  :global(.ant-table-tbody .ant-table-row:not(:nth-child(2))) {
    :global(.ant-table-cell.ant-table-cell) {
      border-top: 1px solid var(--border);
    }
  }

  :global(.MuiLinearProgress-root) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.buttons {
  align-items: center;
  display: flex;
  gap: 16px;
  margin-top: 24px;
}
