.inactiveLoansContainer {
  padding: 32px;
  padding-bottom: calc(32px * 4);
}

.noContent {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}

.paper {
  overflow: hidden;
}

.skeletonPaper {
  .skeletonHeader,
  .skeletonFooter {
    border-radius: 12px;
    margin: 15px;
  }

  .skeletonHeader {
    font-size: 1.2rem;
  }

  .skeletonFooter {
    min-height: 210px;
  }
}
