.accordionSummary.accordionSummary {
  flex-direction: row-reverse;
  padding: 24px;

  &[aria-expanded="true"] {
    padding: 24px 24px 10px;
  }

  .accordionSummaryText {
    margin-left: 8px;
  }
}

.accordionDetails.accordionDetails {
  padding: 0 0 10px;
}
