.wrapper {
  padding: 20px 36px;
}

.documentLink {
  align-items: center;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
  display: flex;
  padding: 8px 0;

  &:last-child {
    border: none;
  }

  &:hover {
    color: var(--gold);
  }

  svg {
    height: 40px;
    margin-right: 12px;
    width: 40px;
  }
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  svg {
    height: 24px;
    width: 24px;
  }
}

.fileName {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    text-transform: none;
  }
}
