.header {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.score {
  &:global(.MuiTypography-root) {
    font-size: 44px;
    font-weight: 600;
  }
}

.scoreResult {
  color: var(--darkBlue);

  &.poor {
    color: var(--error);
  }
}

.scale {
  &.poor {
    [class^="stagesProgress_filled"],
    :global(.MuiLinearProgress-bar) {
      background: var(--error);
    }
  }

  &.fair {
    [class^="stagesProgress_filled"],
    :global(.MuiLinearProgress-bar) {
      background: var(--gold);
    }
  }

  &.good {
    [class^="stagesProgress_filled"],
    :global(.MuiLinearProgress-bar) {
      background: var(--lightGreen);
    }
  }

  &.excellent {
    [class^="stagesProgress_filled"],
    :global(.MuiLinearProgress-bar) {
      background: var(--green);
    }
  }
}

.small {
  line-height: 14px;

  :global(.MuiTypography-root) {
    font-size: 12px;
  }
}
