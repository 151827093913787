.inputStep {
  display: inline-flex;

  :global(.MuiInputBase-input) {
    background-color: var(--backgroundUp);
    border: 1px solid var(--border);
    text-align: center;
    width: 40px;
  }

  :global(.MuiIconButton-root) {
    svg {
      background-color: var(--white);
      border-radius: 50%;
      color: var(--description);
    }
  }

  &.white {
    :global(.MuiInputBase-input) {
      background-color: var(--white);
    }
  }
}

.label.label {
  margin-bottom: 8px;
  text-align: center;
}

.inputStepWrapper {
  text-align: center;
}

.helperText {
  color: var(--description);
  text-align: center;
}
