@import "../../../../../../styles/mixins";

.reviewStatus {
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    margin-top: 2rem;

    & > a {
      text-decoration: none;
    }
  }

  .paperContact {
    align-items: center;
    border: none;
    display: flex;
    margin-top: 1rem;
    padding: 26px;

    svg {
      height: 20px;
      margin-right: 0.5rem;
      width: 20px;
    }

    a {
      color: var(--gold);
      font-weight: bold;
    }
  }

  .paperTop {
    margin-top: 1rem;
  }
}
