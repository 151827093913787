@import "../../styles/placeholders";

.icon {
  width: 24px;
  height: 24px;
}

.controlAdornment.controlAdornment {
  right: 0;
  
  &.opened {
    transform: rotate(180deg) translateX(15px);
  }
}

.controlInput {
  position: relative;
 
  &:before{
    background: transparent;
    content: '';
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.optionsList {
  @extend %scroll-effect;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.option.option  {
  padding: 7px 10px;
  font-weight: 400;
  
  svg {
    margin: 0;
    width: 20px;
    height: 20px;
  }
  
  &.lastSelected {
    border-bottom: 1px solid var(--border);
  }
}

.search {
  &:global(.MuiFormControl-root .MuiInputBase-root.Mui-focused) {
    border-color: transparent;
  }

  .searchInput {
    padding-left: 0;
  }

  .searchIcon {
    margin-left: -8px;
  }
}

.menuBlock.menuBlock {
  border-radius: 8px;
  box-shadow: 0 78px 428px rgba(31, 46, 93, 0.01384), 0 45.9264px 223.245px rgba(31, 46, 93, 0.02192), 0 23.5872px 104.774px rgba(31, 46, 93, 0.02808), 0 9.4848px 45.8816px rgba(31, 46, 93, 0.03616), 0 2.1216px 19.8592px rgba(31, 46, 93, 0.05);
  z-index: 999;
}

.searchWrapper {
  padding: 0 15px;
  border-bottom: 1px solid var(--border);
}

.clearBtnWrapper {
  padding: 35px 20px 20px;
  
  button {
    width: 100%;
  }
}

.label.label {
  margin-bottom: 8px;
}
