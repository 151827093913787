.content {
  padding: 0 28px 28px;

  .fields {
    background-color: var(--backgroundUp);
    border-radius: 12px;
    gap: 24px;
    padding: 32px 24px 28px;

    &:first-of-type {
      input {
        padding-left: 16px;
      }
    }

    .icon {
      height: 22px;
      width: 22px;
    }
  }
}

.footer {
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
}
