.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.section {
  flex-grow: 1;
  padding: 28px;
}
