.footer.footer {
  display: flex;
  margin-top: 48px;
  align-items: center;
  justify-content: flex-end;
}

.button {
  &:global(.MuiButton-root) {
    margin-left: 16px;
  }
}
