div.inputLast:global(.MuiFormControl-root) {
  margin-bottom: 15px;
}

.formFooter {
  align-items: center;
  color: var(--darkBlue);
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
}
