.header {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  height: 88px;
  justify-content: space-between;
  padding: 0 32px;
  width: 100%;

  section {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .logo {
    align-self: center;
    color: var(--darkBlue);
    margin-right: 32px;
    max-height: 48px;
    max-width: 48px;
  }

  .rightBlock {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 32px;

    // justify-content: flex-end;
    padding: 0;
  }
}

.addIcon {
  height: 14px;
  width: 14px;
}
