.footer {
  display: flex;
  justify-content: space-between;

  :global(.MuiTypography-p) {
    color: var(--description);
  }

  &.withArrow {
    gap: 8px;
    justify-content: flex-start;
  }
}

.arrowIcon {
  height: 24px;
  width: 24px;
}
