.ManageConditionPanel {
  width: min(100vw, 720px);

  .header {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 32px;
    position: sticky;
    top: 0;
    z-index: 1;

    & > h4 {
      color: var(--description);
      text-transform: uppercase;
    }
  }

  .container {
    padding: 24px 32px;
  }

  .exception {
    padding: 0;
  }
}
