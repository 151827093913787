.statusComponent {
  .container {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &:global(.MuiPaper-root) {
      padding: 45px 20px;
    }

    .title {
      margin-top: 0.8rem;
    }

    .colorProgress,
    .colorWarning,
    .colorError,
    .colorDone {
      height: 33px;
      margin-left: auto;
      margin-right: auto;
      width: 33px;
    }

    .colorProgress {
      color: var(--darkBlue);
    }

    .colorWarning {
      color: var(--yellow);
    }

    .colorError {
      color: var(--error);
    }

    .colorDone {
      color: var(--darkBlue);
    }
  }
}
