.card {
  + .card {
    margin-top: -8px;
  }
}

.container {
  :global(.MuiFormLabel-root) {
    white-space: pre-wrap;
  }

  :global(.MuiFormLabel-asterisk) {
    display: none;
  }
}

.block {
  &:global(.MuiGrid-root:empty),
  &:global(.MuiGrid-root:has(.MuiGrid-root:empty)),
  &:global(.MuiGrid-root:has(.MuiGrid-root .MuiGrid-root[class*="dynamicBlock_hidden"])) {
    padding-top: 0;
  }
}
