.radioWrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  :global(.MuiLinearProgress-root) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
