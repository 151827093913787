.button {
  &:global(.MuiButton-root) {
    align-items: center;
    background-color: white;
    border: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
  }

  .message {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}
