.notification {
  &:global(.MuiAlert-root) {
    padding: 16px 24px;

    &.successBorder {
      border: 1px solid var(--greenBorder);
    }

    &.warningBorder {
      border: 1px solid var(--goldBorder);
    }

    &.errorBorder {
      border: 1px solid var(--errorBorder);
    }
  }

  :global(.MuiAlert-action) {
    flex-shrink: 0;
  }

  :global(.MuiButton-root) {
    border-color: currentcolor;
    color: inherit;
  }
}
