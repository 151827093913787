.section {
  padding: 32px;
}

.footer {
  background: var(--white);
  padding: 16px 32px;
}

.navigation {
  align-items: center;
  display: flex;
  gap: 16px;
}

.navTitle {
  color: var(--darkBlue);

  &:global(.MuiTypography-root) {
    margin-right: auto;
  }
}

.navButton {
  &:global(.MuiButton-root) {
    margin-left: 16px;
    min-width: 56px;
    padding: 8px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.stepperWidget {
  overflow: hidden;
  padding: 0;
  position: sticky;
  top: 1rem;
}
