.filterButtons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  width: 100%;

  .filterButtonsChip {
    font-weight: normal;
    margin-right: 12px;
  }

  .chipActive {
    background-color: var(--darkBlue);
    color: white;
    font-weight: bold;

    &:hover {
      background-color: var(--darkBlue) !important; // necesary for overriting
    }
  }
}
