.header {
  align-items: center;
  background: var(--white);
  border-bottom: 1px solid var(--border);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 16px 32px;

  .title {
    align-items: center;
    color: var(--darkBlue);
    display: flex;
    gap: 32px;
  }

  .headerBreadcrumbs {
    margin-bottom: 20px;
    width: 100%;
  }

  .headerChips {
    margin-top: 20px;
    width: 100%;
  }
}

.actions {
  display: flex;
  gap: 16px;
}
