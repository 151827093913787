.title {
  transition: color 300ms;
}

.disabled {
  color: var(--disabled);
}

.content {
  margin-top: 32px;

  :global(.MuiFormGroup-root) {
    div:last-child {
      :global(.MuiTypography-root) {
        margin-bottom: 0;
      }
    }
  }
}
