.placeholder {
  color: var(--description);
}

.label {
  &:global(.MuiInputLabel-root) {
    margin-bottom: 8px;
  }
}

div.error {
  &:global(.MuiInputBase-root) {
    border-color: var(--error);
  }
}

.locked {
  :global(.MuiSelect-icon) {
    top: 50%;
    transform: translateY(-50%);
  }
}
