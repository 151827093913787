.footer {
  display: flex;
  justify-content: flex-start;
  padding-top: 24px;
}

.form.form {
  :global(.MuiFormLabel-root) {
    white-space: pre-wrap;
  }

  :global(.MuiGrid-container) {
    align-items: flex-start;
  }

  :global(.MuiFormHelperText-root) {
    color: var(--description);
    font-size: 14px;
  }
}
