.footer {
  background-color: var(--white);
  border-top: 1px solid var(--border);
  bottom: 0;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  left: 0;
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  z-index: 100;
}
