.drawer {
  &:global(.MuiDrawer-paper) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 720px;
  }
}

.section {
  padding: 0 32px 32px;

  &:empty {
    padding: 0;
  }

  &:first-of-type {
    padding-top: 32px;
  }
}

.history {
  background-color: var(--backgroundUp);
  border-top: 1px solid var(--border);
  flex-grow: 1;
  padding: 32px;
}

.title {
  &:global(.MuiTypography-root) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

.text {
  &:global(.MuiTypography-root) {
    color: var(--description);
    margin-bottom: 0;
  }
}

.footer {
  align-items: center;
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 16px 32px;
}
