.icon {
  font-size: 32px;
  line-height: 1;
  margin-bottom: 8px;

  &.disabled {
    color: var(--disabled);
  }

  &.error {
    color: var(--error);
  }
}

.text {
  &:global(.MuiTypography-root) {
    margin-bottom: 0;
  }

  &.error {
    color: var(--error);
  }
}
