.card {
  background: var(--white);
  border: 2px solid var(--white);
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgb(31 46 93 / 5%);
  display: flex;
  gap: 18px;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 100%;
  min-width: 412px;
  padding: 22px;
}

.status {
  flex-shrink: 0;

  svg {
    height: 24px;
    width: 24px;
  }
}

.success {
  .status {
    color: var(--green);
  }
}

.error {
  border-color: var(--error);

  .status {
    color: var(--error);
  }
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.description {
  -webkit-box-orient: vertical;
  color: var(--description);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  max-height: 45px;
  overflow: hidden;
}

.close {
  flex-shrink: 0;
  margin-right: -8px;
  margin-top: -8px;
}
