.container {
  overflow: hidden;

  header {
    background-color: var(--backgroundUp);
    border-bottom: solid 1px var(--border);
    padding: 24px 32px;
  }

  .skeleton {
    margin: 24px 32px;
  }

  .activeLoans a:last-child > div {
    border-bottom: none;
  }
}
