.header {
  align-items: center;
  color: var(--darkBlue);
  display: flex;
  justify-content: space-between;

  .title {
    color: var(--darkBlue);
  }

  .infoIcon {
    color: var(--description);
    height: 24px;
    width: 24px;

    &:hover {
      color: var(--gold);
    }
  }

  :global(.MuiIconButton-root.MuiButtonBase-root) {
    background-color: transparent;
    padding: 0;
  }
}

.chip.chip {
  background: var(--backgroundUp);
  color: var(--description);

  b {
    color: var(--darkBlue);
  }
}
