.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  gap: 12px;
  min-width: 200px;

  > *:first-child {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &:global(.MuiLink-root):hover {
    color: var(--darkBlue);
  }
}

.email {
  color: var(--description);
}

.actions {
  align-self: flex-end;
  display: flex;
  gap: 16px;
  margin-top: 48px;
}
