.drawer {
  max-height: 100vh;
  max-width: 90%;
  overflow: visible;
  width: 480px;

  &:global(.MuiPaper-root) {
    height: 100vh;
    overflow: visible;
  }
}

.controlPanel {
  border: none;
  flex-direction: column;
  height: 130px;

  &.controlPanel section {
    border: none;
    border-bottom: 1px solid var(--border);
    height: 65px;
    max-width: 100%;
    width: 100%;

    &:last-child {
      background-color: var(--backgroundUp);

      & > div {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

.wrapper {
  flex-grow: 0;
  flex-shrink: 1;
  height: 100vh;

  :global(.ant-table-header) {
    display: none;
  }

  :global(.ant-table-tbody .ant-table-row.ant-table-row.ant-table-row.ant-table-row:not(:last-child) .ant-table-cell) {
    border-bottom: 1px solid var(--border);
  }

  :global(.ant-table-tbody .ant-table-row.ant-table-row.ant-table-row.ant-table-row:hover .ant-table-cell) {
    background-color: transparent;
    border-top-color: transparent;
  }
}

.footer {
  align-items: center;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  margin-top: auto;
  padding: 16px 32px;
  position: absolute;
  width: 100%;
}
