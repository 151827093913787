.block {
  background: var(--backgroundUp);
  border-radius: 12px;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
}

p.blockDescription {
  margin-top: 12px;
}

.valuesContainer {
  margin-top: 20px;
}

.valueLine {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.bold {
    :global(.MuiTypography-root) {
      color: var(--darkBlue);
      font-weight: 700;
    }
  }

  &.boldGray {
    :global(.MuiTypography-root) {
      color: var(--description);
      font-weight: 700;
    }
  }

  &.divider {
    border-bottom: 1px solid var(--border);
  }

  &.dividerWide {
    border-bottom: 1px solid var(--border);
    margin: 20px 0;
  }

  &.heading {
    :global(.MuiTypography-root) {
      font-weight: 700;
    }
  }
}

p.valueDescription {
  color: var(--description);
  margin-right: 15px;
}

.value {
  text-align: right;
}
