.table {
  .nameColumn {
    width: 40%;
  }

  .titleColumn,
  .ownershipColumn,
  .assetsColumn {
    width: 20%;
  }

  :global(.MuiTableCell-root) {
    border: none;
    color: var(--darkBlue);
    font-size: 16px;
    padding: 0 8px 16px;
    text-align: left;
    vertical-align: middle;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  :global(.MuiTableHead-root) {
    :global(.MuiTableCell-root) {
      background-color: var(--background);
      border-bottom: 1px solid var(--border);
      padding-bottom: 12px;
      padding-top: 12px;
    }
  }

  :global(.MuiTableRow-root:first-child) {
    :global(.MuiTableCell-root) {
      padding-top: 16px;
    }
  }
}

:global(.MuiTableCell-root) {
  &.totalCount,
  &.totalCountSum {
    border-top: 1px solid var(--border);
    padding-top: 16px;
  }

  &.totalCountSum {
    text-align: center;
  }
}
