h3.label {
  margin-bottom: 20px;
}

.disabled.disabled {
  color: var(--disabled);
  
  svg {
    color: var(--disabled);
  }
}

.infoIcon.infoIcon {
  color: var(--gold);
  height: 24px;
  width: 24px;
}

.iconWrapper {
  margin-left: 12px;
  padding: 0;
}
