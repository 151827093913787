.wrapper {
  align-items: flex-end;
  display: flex;
  gap: 12px;

  .title {
    padding: 16px 0;
    text-align: center;
  }

  .head {
    flex-grow: 1;
    padding-bottom: 24px;
  }

  .item {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: 64px;
    padding: 20px 0;

    &.head {
      justify-content: left;
      padding-left: 0;
    }

    &.buttonRow {
      height: 72px;
      max-height: 72px;
      padding: 16px 0;
    }

    &.inputRow {
      height: 90px;
      max-height: 90px;
    }
  }

  .column {
    border: 1px solid var(--border);
    border-radius: 12px;
    overflow: hidden;
    padding: 24px 10px;
    position: relative;
    width: 20%;

    &.selected,
    &:hover {
      background-color: var(--backgroundUp);
      transition: background-color 300ms;

      :global(.MuiInputBase-input) {
        background-color: var(--white);
      }
    }
  }
}

.actionBlock {
  margin-bottom: 32px;
  overflow: hidden;
  position: relative;

  .loadingIndicator {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

hr.negativeDivider {
  margin: 0 -32px 32px;
}

h3.paragraph {
  margin-bottom: 32px;
}
