.teamMembersListContainer {
  padding: 32px;
}

.noContentWrapper {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}
