.line.line {
  display: flex;
  padding: 0;
  text-align: left;

  svg {
    background: var(--backgroundUp);
    border-radius: 8px;
    flex-shrink: 0;
    height: 48px;
    margin-right: 16px;
    padding: 8px;
    width: 48px;
  }

  h4 {
    color: var(--darkBlue);
  }

  &:hover {
    color: var(--darkBlue);
  }
}
