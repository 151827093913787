.section {
  background-color: var(--background);

  form {
    background: var(--white);
    padding: 24px;
  }

  .subHeaderBtn {
    align-items: center;
    display: flex;
    gap: 16px;
  }
}
