.skeletonTitle {
  font-size: 2rem;
}

.skeletonBody {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.skeletonFooter {
  min-height: 210px;
}

.alert {
  margin-top: 30px;
}

.loaderCircularWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: center;

  .loaderCircular {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    svg {
      margin: 0;
      position: absolute;
    }
  }
}

.modal {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}
