.modal {
  background: var(--white);
  border-radius: 16px;
  box-shadow:
    0 0 1px rgb(30 45 92 / 30%),
    1px 1px 1px 1px rgb(30 45 92 / 30%);
  margin: auto;
  overflow: hidden;
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.header {
  align-items: center;
  color: var(--description);
  display: flex;
  justify-content: space-between;
  min-height: 72px;
  padding: 0 32px;
  position: relative;

  .loadingIndicator {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

.icon {
  color: var(--darkBlue);
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.content {
  color: var(--darkBlue);
  margin-top: 32px;
  padding: 0 32px 32px;

  footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 48px;
  }
}

.noSpace {
  margin-top: 0;
  padding: 0;
}

.footer {
  display: flex;
  gap: 24px;
  justify-content: flex-end;
}
