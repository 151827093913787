.dropZone {
  align-items: center;
  background: var(--background);
  display: flex;
  flex-direction: column;
  padding: 32px;

  & :global(.MuiTypography-pDescription) {
    font-size: 14px;
  }

  &:not(:only-child) {
    border-bottom: 1px solid var(--border);
  }
}
