.task {
  display: flex;
  flex-direction: column;
  position: relative;

  .percentage {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 52px;
    justify-content: center;
    margin-bottom: 16px;
  }

  .percentageMark {
    position: absolute;
  }
}
