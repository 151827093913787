.container {
  .skeleton {
    margin-top: 16px;
  }

  .list {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
    justify-content: space-between;
    padding-inline-start: 0;

    li {
      display: flex;
      flex: 1 1 calc(33% - 24px);
      flex-direction: column;
      list-style: none;
    }
  }
}
