.container {
  .skeleton {
    margin-top: 16px;
  }

  .alertError {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .alertButton {
    display: flex;
    gap: 5px;
  }

  .historic {
    display: flex;
    flex-direction: row;

    .historicIcon {
      font-size: 22px;
      margin-right: 12px;
    }

    .historicText {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .downLoad {
    gap: 10px;
  }
}
