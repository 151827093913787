.manageCondition {
  .skeletonTitle {
    font-size: 2rem;
  }

  .skeletonBody {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .skeletonFooter {
    min-height: 210px;
  }

  .finalUnderwritingButton {
    margin-top: 24px;
  }

  .collapseSection {
    overflow: hidden;

    .collapseHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 24px 24px 24px 32px;
    }

    .collapseContainer {
      padding: 16px 32px 32px;
    }
  }

  .alert {
    margin-top: 30px;
  }
}

.exceptionModal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 50px;
  width: 100%;
}
