.container {
  &:global(.MuiGrid-container) {
    align-items: start;
  }

  &.hidden {
    &:global(.MuiGrid-container) {
      display: none;
    }
  }
}
