.conditionsDisplay {
  border: 1px solid var(--border);
  border-radius: 12px;

  .conditionsAccordeon {
    border-bottom: 1px solid var(--border);
    margin: 0;

    &:first-of-type {
      border-bottom: 1px solid var(--border);
    }

    &:last-child {
      border-bottom: none;
    }

    &::before {
      display: none;
    }

    .accordionSummary {
      padding: 20px 24px;

      > div {
        justify-content: flex-start;
        margin: 0;
      }

      .accordionSummaryText {
        margin-left: 6px;
      }
    }

    .accordionDetails {
      padding-top: 0;
    }
  }
}
