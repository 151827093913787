@import "../../../../styles/mixins";

.sidebar {
  &::before {
    background: url("../../../../assets/images/side-hero-login.jpg");
    background-size: cover;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    @include devices(sm) {
      background-size: cover;
    }
  }

  color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 32px;
  position: relative;

  @include devices(sm) {
    height: auto;
  }
}

.logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  margin-bottom: 16px;
  width: 140px;
  z-index: 5;
}

.nav {
  display: flex;
  justify-content: space-between;

  a {
    color: var(--white);
  }
}

.content {
  margin-bottom: 148px;
  z-index: 5;

  h1,
  span,
  p {
    color: var(--white);
  }

  h1 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 96px;

    @include devices(sm) {
      margin-bottom: 16px;
    }
  }
}

.lightSidebar {
  &.sidebar::before {
    opacity: 0.3;
  }

  .content {
    h1,
    span,
    p {
      color: var(--darkBlue);
    }
  }
}
