.comment {
  &:global(.MuiListItem-root) {
    border-top: 1px solid var(--border);
    padding: 16px 24px;
  }

  :global(.MuiTypography-h3) {
    font-size: 16px;
  }

  :global(.MuiAccordionDetails-root) {
    padding-top: 8px;
  }
}
