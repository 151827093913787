.divider {
  margin-bottom: 24px;
}

.partnerEconomics {
  &:global(.MuiPaper-root) {
    padding: 0;
  }
}

.description {
  &:global(.MuiTypography-root) {
    margin-bottom: 24px;
    margin-top: -8px;
  }

  &.disabled {
    &:global(.MuiTypography-root) {
      color: var(--disabled);
    }
  }
}
