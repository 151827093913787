.headerPanel {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.exceptionRequestButton {
  margin: 15px 0 0;
}

.uploadDescription {
  .uploadDescriptionHead {
    padding: 30px 28px;

    & > button {
      margin-top: 24px;
    }
  }

  .uploadSection {
    border: none;
    border-radius: 12px;
    margin-bottom: 0;
  }

  .uploadDescriptionBrokerHeader {
    background-color: var(--background);
    padding: 24px 24px 12px;
  }
}
