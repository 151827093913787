@import "../../../../../../../../styles/mixins";

.attestationContainer {
  gap: 24px;

  .attestationFieldsContainer {
    display: grid;
    flex-grow: 1;
    gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 24px 0;

    @include devices(md) {
      grid-template-columns: 1fr;
    }
  }

  .attestationCheck {
    background-color: white;
    padding: 16px 24px 18px;
  }
}
