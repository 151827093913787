.listItem {
  &:global(.MuiListItem-root) {
    padding: 12px 24px;
  }

  &:global(.MuiListItem-root:not(:first-child)) {
    border-top: 1px solid var(--border);
  }

  :global(.MuiTypography-paragraph) {
    margin-bottom: 0;
  }

  :global(.MuiTypography-pDescription) {
    font-size: 14px;
    margin-top: 4px;
  }

  :global(.MuiListItemSecondaryAction-root) {
    right: 24px;
  }

  .error {
    color: var(--error);
  }
}

.info {
  width: 90%;
}

.percentage {
  &:global(.MuiTypography-root) {
    color: var(--description);
  }
}

.progress {
  margin-top: 12px;
}
