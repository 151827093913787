.inputTextFieldRoot.inputTextFieldRoot {
  width: 100%;

  input {
    background: var(--background);
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 16px;

    &:focus {
      border: 1px solid var(--description);
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
}

.inputsWrapper {
  display: flex;
  gap: 16px;
}

.inputBaseRoot.inputBaseRoot {
  border: 0;
  height: auto;
}

.inputIcon.inputIcon {
  color: var(--darkBlue);
  height: 24px;
  pointer-events: none;
  right: 14px;
  width: 24px;

  svg {
    height: 100%;
    width: 100%;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 16px;

  button {
    width: 100%;
  }
}

.calendarSection {
  border-top: 1px solid var(--border);
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 24px;
}
