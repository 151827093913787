.drawerPaper {
  max-height: 100vh;
  max-width: 90%;
  width: 880px;
}

.footer {
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  padding: 16px 32px 16px 21px;
  width: 100%;
}

.main {
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
  overflow: hidden;
}
