.profile {
  align-items: center;
  background-color: var(--border);
  border-radius: 50%;
  color: var(--darkBlue);
  cursor: pointer;
  display: flex;
  font-family: Manrope, sans-serif;
  font-size: 20px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  line-height: 28px;
  text-align: center;
  width: 48px;

  img {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.small {
  font-size: 14px;
  height: 32px;
  width: 32px;
}

.large {
  font-size: 42px;
  height: 104px;
  width: 104px;
}
